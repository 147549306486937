import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="#">
                        Exchange <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/">Simple</Link>
                        </li>
                        <li>
                            <Link to="/">Advanced</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        Community <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/">Partnerships</Link>
                        </li>
                        <li>
                            <Link to="/">Projects</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        Resources <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/">About Us</Link>
                        </li>
                        <li>
                            <Link to="/">Research</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#">News</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
