import React from 'react';

function Loader() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            style={{
                margin: 'auto',
                background: 'rgb(255, 255, 255)',
                display: 'block',
                shapeRendering: 'auto',
            }}
            width="75px"
            height="75px"
            viewBox="0 0 73 74.6"

        >

    <path className="rotor" d="m34 26.3-12.3-7.1c.1-.3.1-.5.1-.8 0-1.5-1-2.8-2.4-3.2v-14.2c0-.6-.4-1-1-1s-1 .4-1 1v14.2c-1.4.4-2.4 1.7-2.4 3.2 0 .3 0 .5.1.7l-12.2 7.1c-.5.3-.6.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l12.3-7.1c.6.6 1.4.9 2.3.9s1.7-.3 2.3-.9l12.2 7.1c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.4.1-1.1-.4-1.3zm-15.5-6.5c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4 1.4.6 1.4 1.4-.7 1.4-1.4 1.4z" fill="#9bc924"/>
    <path d="m31.1 72.6h-8.1l-2-53-2 1.4 1.6 44.5h-4.4l1.6-44.5-2-1.4-1.8 53h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h25.3c.6 0 1-.4 1-1s-.5-1-1-1zm-14.9-5h4.6l.2 5h-5z" fill="#9bc924"/>
  <g className="wind" fill="#fff" stroke="#9bc924" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
      <path d="m42.7 40c0-3.3 2.6-5.9 5.9-5.9s5.9 2.6 5.9 5.9-2.6 5.9-5.9 5.9h-16.4"/>
      <path d="m60.2 45.9c0-3.3 2.6-5.9 5.9-5.9s5.9 2.6 5.9 5.9-2.6 5.9-5.9 5.9h-33.9"/>
      <path d="m53.2 63.9c0 3.3 2.6 5.9 5.9 5.9s5.9-2.6 5.9-5.9-2.6-5.9-5.9-5.9h-26.9"/>
    </g>

        </svg>
    );
}

export default Loader;
