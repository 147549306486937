import React from 'react';

function Forms() {
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Questions? We have answers!</h3>
                              <p>Were always looking to help our new and existing users. For all inquiries please contact us through email. Our team is always happy to help simplify your user experience.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                      545 King St. West
                                        <br />  Toronto, Ontario, M5V 1M1
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email Us</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                      hq@empathing.com
                                   </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                          <div className="bisylms-map">
                              <iframe
                                  title="map"
                                  src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=43.6442208,-79.4008747&amp;q=545%20King%20Street%20West%2C%20Toronto%2C%20Canada+(My%20Business%20Name)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
                              ></iframe>
                          </div>
                        </div>
                    </div>
                </div>
            </section>

            </>
    );
}

export default Forms;
